import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {AdminOrgEnvSelectionComponent} from './components/admin-org-env-selection/admin-org-env-selection.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrganizationRolePipe} from './pipes/OrganizationRole.pipe';
import {AlertComponent} from './components/alert/alert.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackbarService} from './services/snackbar.service';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {IntercomComponent} from './components/intercom/intercom.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {
  OrganizationConfigurationComponent
} from './components/organization/organization-configuration/organization-configuration.component';
import {EditOrganizationComponent} from './components/organization/edit-organization/edit-organization.component';
import {PlanDetailsComponent} from './components/plan-details/plan-details.component';
import {HbsptFormComponent} from './components/hbspt-form/hbspt-form.component';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {TimestampToDatePipe} from './pipes/timestamp-to-date.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SubscriptionPlanPipe} from './pipes/subscription-plan.pipe';
import {FeatureAccessDirective} from './directives/feature-access.directive';
import {HoverTextDirective} from './directives/hovertext.directive';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { PlanUsageComponent } from './components/plan-usage/plan-usage.component';
import { DualSliderComponent } from './components/dual-slider/dual-slider.component';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    FooterComponent,
    AdminOrgEnvSelectionComponent,
    OrganizationRolePipe,
    AlertComponent,
    SpinnerComponent,
    IntercomComponent,
    OrganizationConfigurationComponent,
    EditOrganizationComponent,
    PlanDetailsComponent,
    HbsptFormComponent,
    HasPermissionDirective,
    TimestampToDatePipe,
    SubscriptionPlanPipe,
    FeatureAccessDirective,
    HoverTextDirective,
    PlanUsageComponent,
    DualSliderComponent
  ],
    imports: [
        CommonModule,
        MatSnackBarModule,
        FormsModule,
        MatButtonModule,
        RouterModule,
        MatTooltipModule,
        MatIconModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
  providers: [SnackbarService],
    exports: [
        FooterComponent,
        AdminOrgEnvSelectionComponent,
        OrganizationRolePipe,
        SpinnerComponent,
        IntercomComponent,
        OrganizationConfigurationComponent,
        EditOrganizationComponent,
        PlanDetailsComponent,
        HbsptFormComponent,
        HasPermissionDirective,
        TimestampToDatePipe,
        SubscriptionPlanPipe,
        FeatureAccessDirective,
        HoverTextDirective,
        PlanUsageComponent,
        DualSliderComponent
    ]
})
export class SharedModule {
}
