import {Injectable} from '@angular/core';
import {UtilService} from './util.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Organization} from '../models/organization.model';
import {DecodedIdToken} from '../models/idToken.model';
import {getAuth, signOut, sendPasswordResetEmail, onAuthStateChanged} from 'firebase/auth';
import {collection, getFirestore, doc, getDoc} from 'firebase/firestore';
import {COLLECTIONS} from '../constant/constant';
import {ApiUrl} from '../helpers/ApiUrl';
import {HttpClient} from '@angular/common/http';
import {PulseUser} from '../models/pulse-user.model';
import {UserProfileBasicDetails} from '../models/userProfile.model';


@Injectable({
  providedIn: 'root'
})
export class SharedAuthService {

  private decodedIdToken = new BehaviorSubject<DecodedIdToken>(null);
  getDecodedIdToken = this.decodedIdToken.pipe(filter(token => token !== null));

  private initializeUserState = new BehaviorSubject<boolean>(null);
  isCurrentUserPartner = this.initializeUserState.pipe(filter(state => state !== null));

  private initializeOrganizationId = new BehaviorSubject<string>(null);
  getCurrentOrganizationId = this.initializeOrganizationId.pipe(filter(id => id !== null));

  private setUserHasOrganization = new BehaviorSubject<boolean>(null);
  userHasOrganization = this.setUserHasOrganization.pipe(filter(hasOrg => hasOrg !== null));

  private initializeUserRole = new BehaviorSubject<number>(null);
  getCurrentUserRole = this.initializeUserRole.pipe(filter(role => role !== null));

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private router: Router
  ) {
  }

  async doLogout(): Promise<void> {
    const auth = getAuth();
    await signOut(auth);
    this.decodedIdToken.next(null);
    this.initializeUserState.next(null);
    this.initializeOrganizationId.next(null);
    this.initializeUserRole.next(null);
    localStorage.removeItem('currentEnvironment');
    this.router.navigateByUrl('');
  }

  async forgotPassword(email: string) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  }

  getCurrentUserIdSync(): string | null {
    const auth = getAuth();
    return auth.currentUser.uid;
  }

  login(payload): Observable<any> {
    return this.http.post(ApiUrl.SOCIAL_LOGIN, payload);
  }

  getBasicProfileDetails(): Observable<UserProfileBasicDetails> {
    return this.http.get<UserProfileBasicDetails>(ApiUrl.BASIC_PROFILE_DETAILS);
  }

  signup(payload): Observable<any> {
    return this.http.post(ApiUrl.SIGNUP, payload);
  }

  isUserLoggedIn(): Promise<string | null> {
    const auth = getAuth();

    // If auth.currentUser exists, the user is already logged in
    if (auth.currentUser) {
      return Promise.resolve(auth.currentUser.uid);
    }

    // Otherwise, check the auth state using onAuthStateChanged
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          resolve(user.uid);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getCurrentUser(): Promise<PulseUser> {
    return new Promise((resolve, reject) => {
      this.getDecodedIdToken.subscribe(async decodedIdToken => {
        const db = getFirestore();

        const userDocSnap: any = await getDoc(doc(collection(db, COLLECTIONS.USERS), decodedIdToken.user_id));
        resolve(userDocSnap.data());
      }, error => {
        reject(error);
      });
    });
  }

  getCurrentUserOrganization(): Promise<Organization> {
    return new Promise((resolve, reject) => {
      this.getDecodedIdToken.subscribe(async decodedIdToken => {
        const db = getFirestore();
        if (decodedIdToken.custom_claims.organization) {
          const organizationDocSnap: any = await getDoc(doc(collection(db, COLLECTIONS.ORGANIZATIONS), decodedIdToken.custom_claims.organization));
          resolve(organizationDocSnap.data());
        } else {
          resolve(null);
        }
      }, error => {
        reject(error);
      });
    });
  }

  private async initializeIdToken(refresh) {
    const auth = getAuth();
    try {
      const idToken = await auth.currentUser.getIdToken(refresh);
      const decodedToken = this.utilService.decodeJWTToken(idToken);
      this.decodedIdToken.next(decodedToken);
      this.initializeUserState.next(!!decodedToken?.custom_claims?.is_partner);
      this.initializeOrganizationId.next(decodedToken?.custom_claims?.organization);
      this.setUserHasOrganization.next(!!decodedToken?.custom_claims?.organization);
      this.initializeUserRole.next(decodedToken?.custom_claims?.role);
    } catch (error) {
      this.decodedIdToken.next(null);
    }
  }

  initialize(refreshToken = false) {
    return this.initializeIdToken(refreshToken);
  }
}
