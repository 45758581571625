import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {forkJoin, from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {getAuth, getIdToken, onAuthStateChanged} from 'firebase/auth';
import {getToken, initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getApp} from 'firebase/app';

const APIURL = environment;

@Injectable()
export class AppCheckTokenInterceptor implements HttpInterceptor {

  constructor() {
  }


  // This function determines whether a token should be added to the request header based on the URL being requested.
  private shouldAddToken(url: string): boolean {
    return url.startsWith(environment.FareUpThereAPI);
  }

  // This function intercepts HTTP requests and adds a token to the request header if necessary.
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const app = initializeAppCheck(getApp(), {
      provider: new ReCaptchaV3Provider(environment.appCheckSiteKey)
    });

    // Check if token should be added
    if (!this.shouldAddToken(req.url)) {
      return next.handle(req); // If the token doesn't need to be added, pass the original request to the next handler
    }
    try {
      return forkJoin({
        // Get Firebase App Check token
        appCheckToken: getToken(app),
        // Get the current user from Firebase Auth
        currentUser: new Promise(resolve => {
          const unsubscribe = onAuthStateChanged(getAuth(), user => {
            unsubscribe();
            resolve(user);
          });
        })
      }).pipe(
        switchMap(({appCheckToken, currentUser}) => {
          // Define headers to be added to the request
          const headers: any = {
            token: appCheckToken.token,
            'x-platform': 'Pulse',
            environment: APIURL.production ? 'production' : 'staging'
          };
          // If there is a current user, get their ID token and add it to the headers
          if (currentUser) {
            // @ts-ignore
            return from(getIdToken(currentUser)).pipe(
              switchMap((idToken: string) => {
                headers.Authorization = 'Bearer ' + idToken;
                // Clone the original request and add the headers to the clone
                const tokenReq = req.clone({setHeaders: headers});
                // Return the modified request
                return next.handle(tokenReq);
              })
            );
          }

          // If there is no current user, just add the headers to the original request
          const tokenReq = req.clone({setHeaders: headers});
          // Return the modified request
          return next.handle(tokenReq);
        })
      );
    } catch (e) {
      console.log(e);
    }

  }
}
